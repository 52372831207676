import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { createNewAccount, resetError } from "../../redux/modules/users";
import { LoadingSpinner } from "../RedirectChecker/RedirectChecker";
import { identifyUser } from "../../utilities/loadSegment";
import { getAnalyticsProperties } from "../../utilities/getAnalyticsProperties";
import InputGroup from "../InputGroup/InputGroup";
import AuthButton from "../AuthButton/AuthButton";
import Title from "../Title/Title";
import Loading from "../Loading/Loading";
import AuthAlert from "../AuthAlert/AuthAlert";
import AccountCreated from "../CompletedForms/AccountCreated";
import Question from "../AdditionalInfo/Question";
import { DefaultErrorPage } from "../ErrorPage/defaultErrorPage";
import { CookieService, Cookies } from "../../core/services/cookieService";
import { DoUntil } from "@delta-defense/delta-utils";
import './CreateAccount.scss';

const defaultTracking = {
  tID: "genf8f5f0c9e0",
  origin: "Unknown",
  medium: "_",
  campaign: "_",
  audience: "_",
  targeting: "_",
  type: "New Account",
  pageType: "_"
};

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      created: false,
      shaftVerbiage: ''
    };
  }

  componentDidMount() {
    this.props.resetError();

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("email")) {
      const values = this.props.values;
      values.email = queryParams.get("email");
      this.setState({ values: values });
    }

    DoUntil(() => !!window.deltaShaftComplianceVerbiage, 100, 3000).then(() => {
      this.setState({
        ...this.state,
        shaftVerbiage: window.deltaShaftComplianceVerbiage
      });
    });
  }

  tryIdentifyingUser = async (token, user) => {
    const userId = this.props.cookieService.decodeToken(token)?.userId;
    const userData = { userId, ...user };
    identifyUser(userData);
  };

  onChange = (e) => {
    let v = e.target.value.replace(/\D/g, "");
    let newV = "";
    for (let i = 0; i < v.length; i++) {
      if (i < 10) {
        if (i == 3 || i == 6) {
          newV = newV.concat("-");
        }
        newV = newV.concat(v.charAt(i));
      }
    }
    e.target.value = newV;
    this.props.handleChange(e);
  };

  render() {
    const {
      user,
      token: accessToken,
      loading,
      statusCode,
      errors,
      touched,
      values,
      handleKeyUp,
      handleChange,
      resetError,
      status
    } = this.props;
    if (this.state.created) {
      return <AccountCreated />;
    }

    const handledErrorCodes = [409];
    if (statusCode === 201) {
      const { token, ttl } = accessToken;
      (async () => {
        await this.tryIdentifyingUser(token, user);
        await this.props.cookieService.setAccessToken(token, ttl);
        this.setState({ created: true });
      })();
      return <LoadingSpinner />;
    } else if (!!statusCode && !handledErrorCodes.includes(statusCode)) {
      return <DefaultErrorPage errorStatus={statusCode} />;
    }

    return (
      <>
        <Title name="Create Account" />

        <p className="description">
          Gain access to helpful self-defense guides and checklists, preview USCCA's Protector
          Academy &trade;, talk with like-minded people in the Community and much more.
        </p>
        <Form>
          <InputGroup
            id="createAccountFirstName"
            name="firstName"
            label="First Name"
            type="text"
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            onChange={handleChange}
          />

          <InputGroup
            id="createAccountLastName"
            name="lastName"
            label="Last Name"
            type="text"
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            onChange={handleChange}
          />

          <InputGroup
            id="createAccountEmail"
            name="email"
            label="Email"
            type="email"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            onChange={handleChange}
            autoComplete="email"
          />
          {statusCode == 409 && (
            <AuthAlert
              message="The email address you entered is already connected to an account in our system."
              link={
                <Link id="errorSignIn" className="auth-alert__a" to="/">
                  Click here to sign in.
                </Link>
              }
            />
          )}

          <InputGroup
            id="createAccountPhone"
            name="phone"
            label="Mobile Phone"
            type="tel"
            pattern="/^[\d]{3}-[\d]{3}-[\d]{4,}$/"
            optional
            value={values.phone}
            error={errors.phone}
            touched={touched.phone}
            onChange={this.onChange}
          />
          <InputGroup
            id="createAccountPassword"
            name="password"
            label="Password"
            type="password"
            value={values.password}
            error={errors.password}
            touched={touched.password}
            onChange={handleChange}
            autoComplete="new-password"
            passwordUnmasked={status?.passwordUnmasked}
          />

          <p className="mt-1 mb-0 description">Use at least 8 characters</p>

          <InputGroup
            id="createAccountReEnterPassword"
            name="passwordConfirmation"
            label="Password Confirmation"
            type="password"
            value={values.passwordConfirmation}
            error={errors.passwordConfirmation}
            touched={touched.passwordConfirmation}
            onChange={handleChange}
            passwordUnmasked={status?.passwordUnmasked}
          />
          <AuthButton
            id="createAccountSignUpButton"
            color="btn-gold"
            text={loading ? <Loading /> : "Create Free Account"}
            disabled={loading}
          />
        </Form>

        <Question
          headline="Already have an account?"
          link={`/${window.location.search}`}
          text="Log In"
          id="signInTodayButton"
        />

        <p className="shaftVerbiage">{this.state.shaftVerbiage}</p>
      </>
    );
  }
}

const CreateAccountContainer = withFormik({
  mapPropsToValues: (props) => ({
    firstName: "",
    lastName: "",
    email: props.email || "",
    password: "",
    passwordConfirmation: "",
    phone: ""
  }),

  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .min(2, "First name must be at least 2 characters.")
      .max(17, "First name must be less than 18 characters.")
      .required("First name is required"),

    lastName: Yup.string()
      .min(2, "Last name must be at least 2 characters.")
      .max(17, "Last name must be less than 18 characters.")
      .required("Last name is required"),

    email: Yup.string()
      .matches(/[a-zA-Z0-9._%+-]@[a-z0-9.-]+\.[a-z]{2,4}$/, "Email is invalid")
      .required("Email is required"),

    phone: Yup.string().matches(/^[\d]{3}-[\d]{3}-[\d]{4,}$/, "Phone number format is invalid"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .max(40, "Password must be less than 40 characters.")
      .required("Password is required"),

    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required("Password confirmation is required")
  }),

  handleSubmit: async (values, { props, setStatus }) => {
    setStatus({ passwordUnmasked: false });

    const { firstName, lastName, email, password, phone } = values;

    let tracking = undefined;
    try {
      const leadToken = JSON.parse(
        window.atob(await props.cookieService.getRaw(Cookies.USCCA_Leads))
      );
      tracking = {
        ...leadToken
      };
    } catch (error) {
      // do nothing and use defaultTracking
    }

    if (!tracking) {
      tracking = defaultTracking;
      await props.cookieService.setLeadFields(defaultTracking);
    }

    const analyticsProperties = await getAnalyticsProperties();
    const meta = {
      label: analyticsProperties?._LoginModal_parent_window_href || window.location.href,
      fbc: analyticsProperties?.fbc,
      fbp: analyticsProperties?.fbp,
      gaClickID: analyticsProperties?.ga_click_id,
      gaClientID: analyticsProperties?.ga_client_id,
      twclid: analyticsProperties?.twclid
    };

    let newAccountObj = { name: firstName, surname: lastName, email, password, tracking, meta };

    if (phone) {
      newAccountObj = { ...newAccountObj, phone };
    }

    props.createNewAccount(newAccountObj);

    setStatus({ passwordUnmasked: undefined });
  },

  displayName: "Create Account"
})(CreateAccount);

CreateAccountContainer.defaultProps = {
  cookieService: CookieService.Instance(),
  getAnalyticsPropertiesFunc: getAnalyticsProperties
};

const mapStateToProps = ({ users: { email, user, token, loading, statusCode, statusError } }) => {
  return {
    email,
    user,
    token,
    loading,
    statusCode,
    statusError
  };
};

export default connect(mapStateToProps, { createNewAccount, resetError })(CreateAccountContainer);
